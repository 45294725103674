const dictVO = function () {
  return {
    id: '',
    type: '',
    value: '',
    description: '',
    fullName: '',
    hasChildren: false,
    name: '',
    parentId: null,
    parentIds: '',
    sorter: 0,
    creater: '',
    dateCreated: '',
    lastUpdated: ''
  }
}
export default dictVO