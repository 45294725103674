<template>
  <a-spin :spinning="spinShow">
    <a-form layout="horizontal" :form="form">
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item
            label="字典名称">
            <a-input
              v-decorator="['name',
                {rules:[
                  {type:'string',required:true,message:'字典名称不能为空'},
                  {type:'string',max:30,message:'字典名称不能超过30个字符'},
                  ],trigger:'change'}
                ]"
              placeholder="输入字典名称"></a-input>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="设置父节点">
            <a-select
              allowClear
              showSearch
              optionFilterProp="children"
              placeholder="请选择上级节点"
              v-decorator="['parentId']"
              style="width: 100%">
              <a-select-option :key="item.key"
                               :value="item.key"
                               :title="item.value"
                               v-for="item in options">
                {{item.value}}
              </a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="全称">{{formItem.fullName}}</a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="设置排序号（字典显示顺序，asc排列）">
            <a-input-number
              style="width: 110px"
              :step="0.1"
              v-decorator="['sorter', {rules:[
          {type:'number',required:true,message:'排序号必须为数字'}
          ],trigger:'change',initialValue:0}]"
              placeholder="输入排序号"></a-input-number>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="值">
            <a-input v-decorator="['value']" placeholder="请输入值"/>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="类型：">
            <a-input v-decorator="['type']" placeholder="请输入类型"/>
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="描述">
            <a-textarea v-decorator="['description']" rows="4" placeholder="请输入描述"/>
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="编号" v-if="type === 'edit'">
            {{formItem.id}}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="所有父节点ID信息" v-if="type === 'edit'">
            {{formItem.parentIds}}
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="最近更新时间" v-if="type === 'edit'">
            {{formItem.lastUpdated}}
          </a-form-item>
        </a-col>
      </a-row>
      <a-row class="form-row" :gutter="16">
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="创建时间" v-if="type === 'edit'">
            {{formItem.dateCreated}}
          </a-form-item>
        </a-col>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="创建人（用户Username）" v-if="type === 'edit'">
            {{formItem.creater}}
          </a-form-item>
        </a-col>
      </a-row>
      <a-form-item v-if="type === 'edit'">
        <a-affix :offsetBottom="10">
          <a-button type="primary" style="float: right"
                    :loading="ifSub"
                    @click="submitEdit">保存修改
          </a-button>
        </a-affix>
      </a-form-item>
    </a-form>
  </a-spin>
</template>

<script>

  import SERVICE_URLS from '../../../api/service.url'
  import dictVO from '../../../api/vo/settings/dictVO'

  export default {
    name: 'DictionaryForm',
    props: {
      type: {
        type: String,
        default: 'edit'
      }
    },
    data() {
      return {
        options: [],
        formItem: dictVO(),
        spinShow: false,
        form: this.$form.createForm(this),
        ifSub: false
      }
    },
    methods: {
      /*
       * 通过ID获取数据
       */
      loadData(id) {
        const self = this
        this.$http(self, {
          url: SERVICE_URLS.dictionary.view,
          params: {
            id: id
          },
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.options = data.body.select
            //一定要注意 不然会导致双向绑定选择框无法选中值
            this.$nextTick(() => {
              this.setFields(data.body.content)
            })
          }
        })
      },
      setFields(values) {
        Object.assign(this.formItem, values)
        this.formItem.parentId = values.parentId
        this.form.setFieldsValue({
          name: this.formItem.name,
          // fullName: this.formItem.fullName,
          description: this.formItem.description,
          type: this.formItem.type,
          value: this.formItem.value,
          parentId: this.formItem.parentId,
          sorter: this.formItem.sorter
        })
      },
      setVOFields(values) {
        this.formItem.name = values.name
        this.formItem.fullName = values.fullName
        this.formItem.description = values.description
        this.formItem.type = values.type
        this.formItem.parentId = values.parentId
        this.formItem.value = values.value
        this.formItem.sorter = values.sorter
      },
      /**
       * 加载下拉框数据，添加时使用
       * @param id 默认选中值
       */
      loadSelect(id) {
        this.$http(this, {
          url: SERVICE_URLS.dictionary.select,
          loading: 'spinShow',
          noTips: true,
          success: (data) => {
            this.options = data.body
            this.$nextTick(() => {
              this.form.setFieldsValue({
                parentId: id
              })
            })
          }
        })
      },
      submitEdit() {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.dictionary.update,
                data: this.formItem,
                params: {
                  id: this.formItem.id
                },
                success: (data) => {
                  this.setFields(data.body)
                  this.$emit('editSuccess', data.body)
                }
              })
            }
          }
        )
      },
      submitAdd() {
        this.form.validateFields(
          (err, values) => {
            if (!err) {
              this.setVOFields(values)
              this.$http(this, {
                url: SERVICE_URLS.dictionary.create,
                data: this.formItem,
                success: (data) => {
                  //添加成功，清空表单数据，并触发回调
                  this.$emit('addSuccess', data.body)
                  //清空表单数据
                  this.formItem = dictVO()
                  this.form.resetFields()
                },
                error: () => {
                  this.$emit('addError')
                }
              })
            }
          }
        )
      }
    }
  }
</script>